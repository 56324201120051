@charset "EUC-JP";
@use 'sass:list';
@use 'sass:math';

//�ƥ�ץ졼�ȥѡ���
@import '../template/color';
@import '../template/mixin-single';
@import '../template/mixin';
@import '../template/plugins';

.service {
  &__info {
    &__label {
      margin: 2rem 0 1.5rem;
      &__xserver {
        color: #fff;
        font-size: 14px;
        background: #1b63a7;
        padding: 0 1rem;
        display: inline-block;
        margin: 0 .5rem .5rem 0;
      }
      &__xbiz {
        color: #fff;
        font-size: 14px;
        background: #113d6b;
        padding: 0 1rem;
        display: inline-block;
      }
    }
  }
}

.related-services {
  &__single {
    &__label {
      margin: 1.7rem 0 0;
      @include sp {
        margin: 2rem 0 0;
      }

      span {
        color: #fff;
        font-size: 14px;
        background: #1b63a7;
        padding: .3rem 1rem;
      }
    }

    &__vps {
      &__text {
        margin: 62px 0 0;
        @include sp {
          margin: 24px 0;
        }
      }
    }
  }
}