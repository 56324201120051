@use 'sass:list';
@use 'sass:math';

$break-pc: 1206px;
$break-tab-small: 1205px;
$break-tab-middle-upper: 1000px;
$break-tab-middle: 999px;
$break-tab-big: 768px;
$break-sp: 767px;

@mixin pc {
  @media only screen and (min-width: ($break-pc)) {
    @content;
  }
}
@mixin tab-small {
  @media only screen and (max-width: ($break-tab-small)) {
    @content;
  }
}

@mixin tab-big {
  @media only screen and (min-width: ($break-tab-big)) {
    @content;
  }
}

@mixin tab-middle{
  @media only screen and (max-width:($break-tab-middle)){
    @content;
  }
}
@mixin tab-middle-upper{
  @media only screen and (min-width:($break-tab-middle-upper)){
    @content;
  }
}
@mixin tab-middle-only{
  @media only screen and (min-width: ($break-tab-big)) and (max-width: ($break-tab-middle)) {
    @content;
  }
}

@mixin tab-only {
  @media only screen and (min-width: ($break-tab-big)) and (max-width: ($break-tab-small)) {
    @content;
  }
}

@mixin sp {
  @media only screen and (max-width: ($break-sp)) {
    @content;
  }
}

//font-size

@mixin fz($size1: 16, $size2: 14) {
  $size1: math.div($size1, 10);
  $size2: math.div($size2, 10);
  font-size: $size1 + rem;
  @include tab-small {
    font-size: $size2 + rem;
  }
}

//border

@mixin ba($color: $border1, $size: 1px) {
  border: $size solid $color;
}

@mixin bb($color: $border1, $size: 1px) {
  border-bottom: $size solid $color;
}

@mixin bt($color: $border1, $size: 1px) {
  border-top: $size solid $color;
}

@mixin bl($color: $border1, $size: 1px) {
  border-left: $size solid $color;
}

@mixin br($color: $border1, $size: 1px) {
  border-right: $size solid $color;
}

//width

@mixin width($pc) {
  width: 100%;
  max-width: calc(#{$pc} + 40px);
  margin: 0 auto;
  padding: 0 20px;

  @include sp {
    padding: 0 15px;
    width: 480px;
    max-width: 100%;
  }
}

//display:flex;

@mixin flex($justify: space-between, $align: stretch) {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: $justify;
  align-items: $align;
}

@mixin flextop($tab, $num3, $sp, $num2) {
  @include tab-big {
    margin-top: $tab + px;

    @for $i from 1 through $num3 {
      &:nth-of-type(#{$i}) {
        margin-top: 0;
      }
    }
  }

  @include sp {
    margin-top: $sp + px;

    @for $i from 1 through $num2 {
      &:nth-of-type(#{$i}) {
        margin-top: 0;
      }
    }
  }
}

//flex-basis

@mixin basis($i: 1, $y: 0, $width: 1080) {
  $basis: 100%;
  @if $i != 1 {
    $basis: percentage(
      math.div(math.div(100 - math.div($y, $width) * 100, $i), 100)
    );
  }
  flex-basis: $basis;
  max-width:$basis;
}

@mixin basis_s($pc) {
  flex-basis: $pc;
  max-width:$pc;
}

@mixin lh($pc: 16, $sp: 14) {
  line-height: $pc + px;

  @include sp {
    line-height: $sp + px;
  }
}

//margin

@mixin mt($pc, $tab, $sp) {
  margin-top: $pc;

  @include tab-small {
    margin-top: $tab;
  }

  @include sp {
    margin-top: $sp;
  }
}

@mixin mb($pc, $tab, $sp) {
  margin-bottom: $pc;

  @include tab-small {
    margin-bottom: $tab;
  }

  @include sp {
    margin-bottom: $sp;
  }
}

//padding

@mixin pt($pc, $tab, $sp) {
  padding-top: $pc;

  @include tab-small {
    padding-top: $tab;
  }

  @include sp {
    padding-top: $sp;
  }
}

@mixin pb($pc, $tab, $sp) {
  padding-bottom: $pc;

  @include tab-small {
    padding-bottom: $tab;
  }

  @include sp {
    padding-bottom: $sp;
  }
}

@mixin scbar($color: rgba(0, 0, 50, 0.5)) {
  /*スクロールバー全体*/
  /*@include tab-big {
    &::-webkit-scrollbar {
      width: 4px;
      position: absolute;
      background: #ccc;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $color;
      border-radius: 10px;
      opacity: 0.3;
    }
  }*/
}

@mixin scbarY($color: rgba(0, 0, 50, 0.5)) {
  /*スクロールバー全体*/
  /*@include tab-big {
    &::-webkit-scrollbar {
      height: 4px;
      position: absolute;
      background: #ccc;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $color;
      border-radius: 10px;
      opacity: 0.3;
    }
  }*/
}
