.service__info__label
{
    margin: 2rem 0 1.5rem;
}
.service__info__label__xserver
{
    font-size: 14px;

    display: inline-block;

    margin: 0 .5rem .5rem 0;
    padding: 0 1rem;

    color: #fff;
    background: #1b63a7;
}
.service__info__label__xbiz
{
    font-size: 14px;

    display: inline-block;

    padding: 0 1rem;

    color: #fff;
    background: #113d6b;
}

.related-services__single__label
{
    margin: 1.7rem 0 0;
}
@media only screen and (max-width: 767px)
{
    .related-services__single__label
    {
        margin: 2rem 0 0;
    }
}
.related-services__single__label span
{
    font-size: 14px;

    padding: .3rem 1rem;

    color: #fff;
    background: #1b63a7;
}
.related-services__single__vps__text
{
    margin: 62px 0 0;
}
@media only screen and (max-width: 767px)
{
    .related-services__single__vps__text
    {
        margin: 24px 0;
    }
}
